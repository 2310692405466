<!--
 * @Author: chen 374101491@qq.com
 * @Date: 2022-11-14 14:49:02
 * @LastEditors: chen 374101491@qq.com
 * @LastEditTime: 2022-11-15 16:26:42
 * @FilePath: \FlowadAgentWebd:\wwwroot\UserWeb\src\views\login\tokenLogin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="login">
	</div>
</template>

<script>
export default {
	data () {
		return {
		}
	},

	mounted(){
		this.onTokenRoute()
	},

	methods: {
		onTokenRoute(){
			console.log(this.$route)
			if(localStorage.getItem('TZ-USER')){
				window.location.href = this.$route.query.path+this.$route.hash
				// this.$router.push()
			}else{
				this.onTokenUser()
			}
		},
		//获取用户信息
		onTokenUser(){
			var param = {
				api_token:this.$route.query.tokenLogin
			}
			this.$service.get(this.$api.getUserProfiles,param, (res)=> {
				if(res.code == '200'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data));
					console.log(this.$route.query)
					setTimeout(() => {
						window.location.href = this.$route.query.path+this.$route.hash
					}, 1000);
				}
			})
		}
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	}
}
</script>

<style lang="scss" scoped>
.login{
	
}
</style>
